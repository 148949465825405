<template>
  <div>
    <cta-banner :title="$route.name"/>

    <section class="container mx-auto" v-if="plantillas.length > 0" >
      <div class="w-full pt-12">
        <h2 class="text-3xl font-bold mb-7 title relative">Plantillas presentaciones y poster</h2>
      </div>
      <div class="container px-5 pb-16 mx-auto">
        <div class="flex flex-wrap -m-4" >
          <div class="p-4 lg:w-1/2 md:w-full" v-for="plantilla in plantillas" :key="plantilla.nombre">
            <div class="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col">
              <div class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-gray-100 text-gray-400 flex-shrink-0">
                <FileDocumentOutline :size="30" />
              </div>
              <div class="flex-grow">
                <h2 class="text-gray-900 text-lg title-font font-medium mb-3 tracking-tight">{{plantilla.nombre}}</h2>
                <p class="leading-relaxed text-base">{{plantilla.descripcion}}</p>
                <span class="px-6 py-2 mt-5 inline-flex items-center text-xs leading-5 font-semibold rounded-full bg-red-100 text-theme-color p-1">
                      <ArrowCollapseDown :size="18" />
                    <a :href="plantilla.documento.replace('http://', 'https://')" target="_blank" :download="plantilla.nombre" class="pl-2">
                      Descargar plantilla</a>
                  </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container mx-auto ">
      <div class="w-full pb-12">
        <h2 class="text-3xl font-bold mb-7 title relative">{{ trabajos.titulo }}</h2>
        <p v-html="trabajos.cuerpo" style="white-space: pre-wrap" align="justify"></p>
      </div>
      <div class="w-full pb-12">
        <h2 class="text-3xl font-bold mb-7 title relative">{{ recomendaciones.titulo }}</h2>
        <p v-html="recomendaciones.cuerpo" style="white-space: pre-wrap" align="justify"></p>
      </div>
    </section>
    

  </div>
</template>

<script>
import API from "@/api";
import CtaBanner from '../../components/common/CtaBanner.vue';
import ArrowCollapseDown from 'vue-material-design-icons/ArrowCollapseDown.vue';
import FileDocumentOutline from 'vue-material-design-icons/FileDocumentOutline.vue';
import {mapState} from "vuex";

export default {
  name: "Plantillas",
  components: {
    CtaBanner,
    ArrowCollapseDown,
    FileDocumentOutline
  },
  data() {
    return {
      trabajos: {},
      recomendaciones: {},
      plantillas: [],      
    };
  },
  computed: {
    ...mapState(["idEventoActual"]),
  },
  async created() {
    this.getPlantillas();
    this.getInfo();
  },
  methods: { 
    async getPlantillas(){
      const params = {
        "evento_fk" : this.idEventoActual,
        format:  "json",
      };
      const { data } = await API.getPlantillas(params);
      this.plantillas = data.results
    },
    async getInfo(){
      const params = {
        "evento_fk" : this.idEventoActual,
        format:  "json"
      };
      const { data } = await API.getInfo(params);
      let items = data.results;
      for (let i in items){
        this.recomendaciones = (items[i].codigo === 'AU01') ? items[i] : this.recomendaciones;
        this.trabajos = (items[i].codigo === 'AU02') ? items[i] : this.trabajos;
      }
    },
  }
}
</script>

<style>
.color_a {
      color: #0d6efd;
  }
</style>
